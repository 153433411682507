@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
:root{
    --main-bg-color: #039df9;
    --themeGradient:linear-gradient(to right, #315dce, #039df9);
    --theme-light:#fff;
    --color-dark-1: #05073c;
    --black:#000;
    --color-light-2: #717171;
}
a{text-decoration: none;}
body{
    margin: 0;
    font-family: "Jost", sans-serif;
    font-size: 15px;
}
.menu_style{
    .nav-link{
        position: relative;
        &::after{
            position: absolute;
            transition: width 0.3s;
            width: 0;
            top: 100%;
            content: '';
            left: 0;
            bottom: 0;
            background: #000;
            height: 1px;
        }
        &:hover{
            color: #000;
            &::after {
                width: 100%;
            }
        }
    }
}
.head_top{
    background: var(--themeGradient);
    padding: 2px 0;
    ul{
        margin: 0;
        padding: 0;
        text-align: right;
        li{
            display: inline-block;
            padding: 0 0.7em;
            color: #fff;
        }
    }
}
.text-light-2{color: var(--color-light-2);}
.carousel-indicators{margin: 0 !important;}
.form-control{padding: 0.7rem 1rem;}
.btn{padding: 0.7rem 1.5rem;}
// .carousel-item img{width: 100%  !important;}
// .tour_packages{
//     a {
//             position: relative;
//             display: block;
//             width: 100%;
//             margin: 40px auto;
//             color: #a8aabc;
//             border: 1px solid #a8aabc;
//             overflow: hidden;
    
//             .show {
//                 position: relative;
//                 height: 200px;
    
//                 h1 {
//                     position: absolute;
//                     top: 50%;
//                     left: 50%;
//                     font-size: 1.5em;
//                     transform: translate(-50%, -50%);
//                 }
//             }
    
//             .hide {
//                 position: absolute;
//                 left: 0;
//                 bottom: 0;
//                 width: 100%;
//                 height: auto;
//                 padding: 28px;
//                 color: #ffffff;
//                 background-color: #384878;
//                 transform: translateY(100%);
//                 transition: transform .5s;
//             }
    
//             &:hover {
//                 .hide {
//                     transform: translateY(0);
//                 }
//             }
//         }
// }




.tour_packages {
    // background: #eee;
    h2{font-weight: bold;}
    .card_items{
        text-decoration: none;
        display: block;
        background: var(--theme-light);
        border: rgba(113, 113, 113, 0.35) 1px solid;
        padding: 5px;
        overflow: hidden;
        .card__image1{
            position: relative;
            overflow: hidden;
            display: block;
            border-radius: 10px;
            &::before{
                content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(1deg, rgba(21, 47, 0, 0.56) 1.03%, rgba(21, 47, 0, 0) 90.67%);
                    border-radius: 10px;
                    z-index: 1;
            }
            &::after{
                position: absolute;
                width: 200%;
                height: 0%;
                left: 50%;
                top: 50%;
                background-color: rgba(255, 255, 255, .3);
                transform: translate(-50%, -50%) rotate(-45deg);
                content: "";
                z-index: 1;
            }
        }
        
        .card__image{
            width: 100%;
            height: 185px;
            overflow: hidden;
            object-fit: cover;
            // display: block;
            // transition: all 1.5s cubic-bezier(0, 0, .2, 1);

            transition: all .5s ease-out;
        }
        .card_items_inn{
            padding: 0.8em 0.8em 0.2em 0.8em;
            h4{
                font-size: 17px;
                padding: 0.5em 0 0 0;
                color: var(--color-dark-1);
                position: relative;
                    &::after{
                        display: block;
                        content: '';
                        border-bottom: solid 1px var(--color-dark-1);
                        transform: scaleX(0);
                        transition: transform 300ms ease-in-out;
                    }
            }
            p{color: var(--color-light-2); line-height: 20px; font-size: 14px;}
            .text-light-2{
                font-size: 13px;
            }
        }
        .card_footer{
            color: var(--color-dark-1);
        }
        &:hover{
            border-color: #eb5e00;
            .card__image1 {
                &::after{
                    height: 250%;
                    transition: all 600ms linear;
                    background-color: transparent;
                }
            }
            .card__image{transform: scale(1.1);}
            // img{
            //     transform: scale3d(1.1, 1.1, 1.1);
               
            // }
            .card_items_inn {
                h4 {
                    color: var(--black);
                &::after {
                    transform: scaleX(1);
                        transform-origin: 0 50%;
                    }
                }
            }
        }
    }
}
.dark {
.tour_packages {
    .card_items {
        background-color: var(--black);
        color: var(--theme-light);
        h4{
            color: var(--theme-light);
        }
        .card_footer{
            color: var(--theme-light);
        }
    }
    &:hover{
    .card_items {
            h4 {
                color: var(--theme-light);
                &::after{
                    border-color: #eb5e00;
                }
            }
        }
    }
}
.page_about {background-color: var(--black);}
.page_about .about_list li{
    background: #101010;
    border-color: #303030 !important;
}
}

// .card__image {
//     width: 100%;
//     height: 200px;
// }

// .card__overlay {
//     position: absolute;
//     bottom: -45px;
//     left: 0;
//     right: 0;
//     z-index: 1;
//     background-color: var(--theme-light);
//     transform: translateY(100%);
//     transition: .2s ease-in-out;
// }

// .card_items:hover .card__overlay {
//     transform: translateY(0);
//     bottom: 0;
// }

// .card__header {
//     position: relative;
//     // display: flex;
//     // align-items: center;
//     // gap: 2em;
//     padding: 1em;
//     background-color: var(--theme-light);
//     transform: translateY(-100%);
//     transition: .2s ease-in-out;
//     .card__header-text{
//         h3 {
//             font-size: 17px;
//             color: #000;
//         }
//     }
    
// }

// .card_items:hover .card__header {
//     transform: translateY(0);
// }


// .card__description {
//     padding:0 1em;
//     bottom: 0;
//     margin: 0;
//     color: #D7BDCA;
//     display: -webkit-box;
//     -webkit-box-orient: vertical;
//     -webkit-line-clamp: 3;
//     overflow: hidden;
// }
.page_about{
    padding: 3em 0;
    // background: #cce4c2;
    // background: #d3bed5;
    background: #f0e8e7;
    p{
        text-align: justify;
    }
    .about-img-bx{
        position: relative;
        .about-img{
            position: relative;
            mask-image: url(../images/about-img-bg.png);
            mask-size: cover;
            mask-repeat: no-repeat;
            &::after{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(16, 12, 8, .3);
                z-index: 1;
            }
            img{
                width: 100%;
                // height: 390px;
                object-fit: cover;
                display: block;
            }
        }
    }
    .about_list{
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 15px;
        li{
            display: inline-block;
            flex: 0 0 auto;
            width: 48.6%;
            border: #706ee7 1px solid;
            animation: mymove 5s infinite;
            padding: 0;
            border-radius: 45px;
            background: #fff;
            // text-align: center;
            font-weight: bold;
            span{
                display: inline-block;
                border-radius: 100%;
                background: #706ee7;
                width: 35px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                margin-left: -1px;
                margin-right: 0.5em;
            }
            svg{
                color: #fff;
            }
        }
    }
}
@keyframes mymove {
    50% {
        border-color: lightblue;
    }
}
.primary-btn3 {
    border-radius: 35px;
    background-color: var(--main-bg-color);
    // font-weight: 600;
    font-size: 16px;
    color: var(--theme-light);
    letter-spacing: .48px;
    text-transform: capitalize;
    line-height: 1;
    padding: 15px 34px;
    display: inline-flex;
    align-items: center;
    gap: 7px;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    white-space: nowrap;
    border-bottom: 2px solid #4a8631;
    text-decoration: none;
}

.primary-btn3::after {
    position: absolute;
    content: "";
    border-radius: 30px;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: var(--black);
    transform-origin: right;
    transform: scalex(0);
    z-index: -1;
    transition: transform .7s
}

.primary-btn3:hover {
    color: var(--theme-light)
}

.primary-btn3:hover::after {
    transform: scalex(1);
    transform-origin: left
}
.tour_packages2 {
    // background: #eee;
    h2 {
        font-weight: bold;
    }

    .card_items {
        text-decoration: none;
        display: block;
        background: var(--theme-light);
        overflow: hidden;
        border-radius: 8px;
        position: relative;
        &::after {
            background: linear-gradient(to top, rgba(0, 0, 0, 0.75), rgba(255, 0, 0, 0));
            position: absolute;
            width: 100%;
            z-index: 1;
            height: 100%;
            bottom: 0;
            content: '';
            left: 0;
        }
        .transition5{
            position: relative;
            overflow: hidden;
            display: block;
           
        }
        .card__image {
            width: 100%;
            height: 280px;
            overflow: hidden;
            object-fit: cover;
            // display: block;
            transition: all .5s ease-out;
           
        }
        .hm-content{
            position: absolute;
            bottom: -43px;
            transition: bottom ease 0.5s;
            text-align: center;
           z-index: 2;
           h4{
            color: #fff;
           }
            .btn_detail{
                // opacity: 0;
                // display: none;
                transition: bottom ease 0.5s;
            }
        }

        &:hover {
            border-color: #eb5e00;
            .hm-content {
                bottom: 10%;
                .btn_detail {
                    // opacity: 1;
                    // display: inline-block;
                    transition: bottom ease 0.5s;
                }
            }
            .card__image {
                    transform: scale(1.1);
            }
          
        }
    }
}

.footer_style{
    background: #000;
    padding: 3em 0;
    color: var(--theme-light);
    // h5{
    //     position: relative;
    //     color: var(--theme-light);
    //     &::before{
    //     width: 60px;
    //     height: 2px;
    //     background-color: #eb5e00;
    //     left: 0;
    //     bottom: 0;
    //     content: "";
    //     position: absolute;
    //     }
    //     &::after{
    //         width: 5px;
    //         height: 5px;
    //         outline: 4px solid #ff681a;
    //         border-radius: 50%;
    //         background-color: var(--theme-light);
    //         left: 4px;
    //         bottom: -1px;
    //         animation: leftToRight infinite 5s linear;
    //         content: "";
    //             position: absolute;
    //     }
    // }
    ul{
        margin: 0;
        padding: 0;
        li{
            display: block;
            color: var(--theme-light);
            line-height: 28px;
            a{
                color: var(--theme-light);
                text-decoration: none;
                position: relative;
                &::after {
                        content: "";
                        display: block;
                        width: 0;
                        height:1px;
                        background: rgba(255, 255, 255, 0.286);
                        transition: width 0.3s;
                        top: 100%;
                        position: absolute;
                        left: 0;
                        right: 0;
                    }
                &:hover{
                    color: rgba(255, 255, 255, 0.418);
                    &::after {
                       width: 100%;
                    }
                }
            }
        }
    }
}
.f_title {
    border: none;
    // font-size: 22px;
    margin: 0 0 20px 0;
    padding: 0 0 10px 0;
    color: #ffffff;
    position: relative;
    &::before,
    &::after {
        content: "";
        position: absolute;
    }
    &::before {
        width: 60px;
        height: 2px;
        background-color: #FF681A;
        left: 0;
        bottom: 0;
    }

    &::after {
        width: 5px;
        height: 5px;
        outline: 4px solid #FF681A;
        border-radius: 50%;
        background-color: #ffffff;
        left: 4px;
        bottom: -1px;
        animation: leftRight infinite 5s linear;
    }
}
@keyframes leftRight {
    0% {
        left: 0px;
    }

    50% {
        left: 56px;
    }

    100% {
        left: 0px;
    }
}

.scroll-to-top {
    position: fixed;
    bottom: 40px;
    right: 20px;
    z-index: 1000;
}

.scroll-to-top button {
    // padding: 10px;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    display: block;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
}

.scroll-to-top button:hover {
    background-color: #0056b3;
}
.page_heaer{
    padding: 1.3em 0;
    background: var(--black);
    margin-bottom: 1em  ;
    color: var(--theme-light);
    ol{
        li{
            color: var(--theme-light);
            a{
                color: var(--theme-light);
            }
        }
        .active {
            color: rgba(255, 255, 255, 0.445) !important;
        }
    }
}
.breadcrumb-item+.breadcrumb-item::before{color: rgba(255, 255, 255, 0.45) !important;}
.page_inn{
    min-height: 45vh;
    margin: 2em 0;
    p{
        text-align: justify;
    }
    .img_bx{
        float: left;
        margin-right: 1.5em;
        margin-bottom: 1em;
        img{
            max-width: 500px;
        }
    }
}
.menu_footer{display: none;}
.gallery_img{
    img{
        height: 100px;
        object-fit: cover;
    }
}
@media only screen and (max-width: 768px) {
    .page_inn {
            .img_bx {
                float: none;
                margin-right: 0;
                margin-bottom: 1em;
    
                img {
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
        .navbar-brand{
            img{
                max-width: 50vh;
            }
        }
        .banner_style{
            img{height: auto; width: 100%;}
        }
        .head_top{
            ul{
                text-align: center;
                li{
                    font-size: 0.8em;
                }
            }
        } 
        .main_menu_off{
            max-width: 300px;
            img{
                max-width: 80%;
            }
        }
        .menu_footer{
            display: block;
            padding: 1em;
            background: var(--black);
            color: var(--theme-light);
        }
        .tour_packages{
            .card_items{
                .card_items_inn{
                    h4{
                        font-size: 13px;
                    }
                    p{
                        line-height: normal;
                        font-size: 0.8em;
                    }
                   
                }
                .card_footer{
                    font-size: 0.8em;
                }
                 .card__image{
                        height: 123px;
                    }
            }
        }
    .tour_packages2{
.card_items{
    .hm-content {
            h4 {font-size: 0.9em;}
        }
        .card__image{
            height: 180px;
        }
}
    }
        .page_about{
             .about_list{
                li{
                  width: 100%;  
                }
             } 
        }
}
.tour_itinerary{
    border-left: #eb5e00 1px dotted;
    position: relative;
    margin-left: 9px;
    .tour_i_inn{
        margin-bottom: 1em;
    }
    .title_style_acc {
        &::before {
                position: absolute;
                left: -9px;
                content: '';
                border: #eb662b 3px solid;
                background: #fff;
                width: 18px;
                height: 18px;
                border-radius: 18px;
            }
           
    }
}
.title_style_acc{
    padding-left: 1em;
    cursor: pointer;
    font-weight: bold;
   padding-bottom: 0.5em;
   display: inline-block;
}
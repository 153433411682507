.checkbox {
  opacity: 0;
  position: absolute;
}
.checkbox-label {
  background-color: #111;
  width: 50px;
  height: 27px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fa-moon {
  color: #f1c40f;
}

.fa-sun {
  color: #f39c12;
}

.checkbox-label .ball {
  background-color: #fff;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.checkbox:checked+.checkbox-label .ball {
  transform: translateX(24px);
}
.dark {
  background-color: #333;
  color: #fff;
}

/* .dark label {
  background-color: #666;
} */

/* .dark label:hover {
  background-color: #555;
} */
.dark .menu_style{background: #111 !important;}
.dark .menu_style .nav-link{color: #fff;}
.dark .menu_style .nav-link:hover{color: #e87442;}
.dark .navbar-nav .nav-link.active,
.dark .navbar-nav .nav-link.show{color: #e87442;}
.dark .navbar-brand{color: #fff;}
.dark .menu_style .nav-link::after{background-color: #e87442;}
.dark .navbar-toggler-icon{background-image:var(#fff) !important}
.dark .navbar-toggler{color: #fff !important; border-color: #fff}